<template>
	<b-modal
		:title="`Courier Location`"
		id="incident-report-location-view"
		:no-close-on-backdrop="true"
		@shown="refreshMap"
		size="lg"
		ok-only
		centered
	>
		<b-row>
			<b-col sm="12">
				<GmapMap
					ref="directions-map"
					:center="sourceDetails"
					:zoom="12"
					:style="{ width: '100%', height: '500px' }"
				>
					<GmapMarker
						:position="sourceDetails"
						:clickable="true"
						@click="sourceMarkerInfo = !sourceMarkerInfo"
						:icon="sourceDetails.focusedIcon"
					>
						<gmap-info-window
							ref="source-info-map"
							:opened="sourceMarkerInfo"
							@closeclick="sourceMarkerInfo = false"
						>
							<b class="text-success">SOURCE</b>
							<br /><br />
							Company Name: <b>{{ sourceDetails.name }}</b>
							<br />
							Storage Location: <b>{{ sourceDetails.storage }}</b>
						</gmap-info-window>
					</GmapMarker>

					<GmapMarker
						:position="destinationDetails"
						:clickable="true"
						@click="destinationMarkerInfo = !destinationMarkerInfo"
						:icon="destinationDetails.focusedIcon"
					>
						<gmap-info-window
							:opened="destinationMarkerInfo"
							@closeclick="destinationMarkerInfo = false"
						>
							<b class="text-success">DESTINATION</b>
							<br /><br />
							Company Name: <b>{{ destinationDetails.name }}</b>
							<br />
							Storage Location: <b>{{ destinationDetails.storage }}</b>
						</gmap-info-window>
					</GmapMarker>

					<GmapMarker
						:position="courierDetails"
						:clickable="true"
						@click="courierMakerInfo = !courierMakerInfo"
						:icon="courierDetails.focusedIcon"
					>
						<gmap-info-window
							:opened="courierMakerInfo"
							@closeclick="courierMakerInfo = false"
						>
							<b class="text-success">COURIER</b>
							<br /><br />
							Driver: <b>{{ courierDetails.driver }}</b>
							<br />
							Plate No: <b>{{ courierDetails.plateNo }}</b>
						</gmap-info-window>
					</GmapMarker>
				</GmapMap>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import EventBus from '@/shared/event-bus';
export default {
	name: 'dispatch-alert-report-location-view',
	data() {
		return {
			dispatchLocationDetails: {},
			sourceMarkerInfo: false,
			destinationMarkerInfo: false,
			courierMakerInfo: false,
			focusedIcon: {
				url: require('../../../assets/images/green-pin.png'),
				scaledSize: { width: 25, height: 39 },
			},
		};
	},
	computed: {
		sourceDetails() {
			let source = this.dispatchLocationDetails.source;
			if (source) {
				return {
					lat: source.geoaddress.latitude,
					lng: source.geoaddress.longitude,
					name: source.company,
					storage: source.storageLocation,
					focusedIcon: {},
				};
			} else return {};
		},
		destinationDetails() {
			let destination = this.dispatchLocationDetails.destination;
			if (destination) {
				return {
					lat: destination.geoaddress.latitude,
					lng: destination.geoaddress.longitude,
					name: destination.company,
					storage: destination.storageLocation,
					focusedIcon: {},
				};
			} else return {};
		},
		courierDetails() {
			let courier = this.dispatchLocationDetails.courier;
			if (courier) {
				return {
					lat: courier.geoaddress.latitude,
					lng: courier.geoaddress.longitude,
					driver: courier.driver,
					plateNo: courier.plateNo,
					focusedIcon: this.focusedIcon,
				};
			} else return {};
		},
	},
	mounted() {
		EventBus.$on(
			'onUpdateSelIncidentReportLocationView',
			(dispatchLocationDetails) => {
				this.dispatchLocationDetails = dispatchLocationDetails;
			}
		);
	},
	methods: {
		refreshMap() {
			this.$gmapDefaultResizeBus.$emit('resize');
			this.sourceMarkerInfo = true;
			this.destinationMarkerInfo = true;
			this.courierMakerInfo = true;
			this.drawDirection();
		},
		drawDirection() {
			let directionsService = new google.maps.DirectionsService();
			let directionsDisplay = new google.maps.DirectionsRenderer({
				suppressInfoWindows: true,
				suppressMarkers: true,
			});
			directionsDisplay.setMap(this.$refs['directions-map'].$mapObject);

			this.calculateAndDisplayRoute(
				directionsService,
				directionsDisplay,
				this.sourceDetails,
				this.destinationDetails
			);
		},
		calculateAndDisplayRoute(
			directionsService,
			directionsDisplay,
			origin,
			destination
		) {
			directionsService.route(
				{
					origin: origin,
					destination: destination,
					travelMode: 'DRIVING',
				},
				function (response, status) {
					if (status === 'OK') {
						directionsDisplay.setDirections(response);
					} else {
						window.alert('Directions request failed due to ' + status);
					}
				}
			);
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelIncidentReportLocationView');
	},
};
</script>