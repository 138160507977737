<template>
    <b-card>
        <b-tabs>
            <b-tab title="Primary Details" active>
                <b-card-text>

                    <b-form-group label="Incident ID" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">{{ row.item.incidentId }}</span>
                    </b-form-group>

                    <b-form-group label="Type" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span>{{ row.item.incidentType.name }} </span>
                    </b-form-group>

                    <b-form-group label="Summary" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span>{{ row.item.summary }}</span>
                    </b-form-group>

                    <b-form-group label="Dispatch ID" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">{{ row.item.dispatchId }}</span>
                    </b-form-group>

                    <b-form-group label="Source" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span>{{ sourceCompany }}</span>
                    </b-form-group>

                    <b-form-group label="Destination" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span>{{ destinationCompany }}</span>
                        &nbsp;&nbsp;
                    </b-form-group>

                    <b-form-group label="Location" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <strong>
                            <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
                                @click.stop="showDispatchLocation(row.item)" variant="dark" class="mr-1">
                                <em class="fa fa-map-marker fa-lg"></em>
                            </b-button>
                        </strong>
                        <span class="numFont">
                            {{ getCoordinates(row.item.geoaddress) }}
                        </span>
                    </b-form-group>

                    <b-form-group label="Driver" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ row.item.driver.name }}
                    </b-form-group>

                    <b-form-group label="Plate No./Conduction No." label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        <span class="numFont">
                            {{ row.item.transportation.plateNo }}
                        </span>
                    </b-form-group>

                    <b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
                        label-align-sm="right">
                        {{ showFormattedDate(row.item.dateCreated) }}
                    </b-form-group>
                </b-card-text>
            </b-tab>

            <b-tab title="Images">
                <b-card-text>
                    <b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Images</b>
						</b-col>
						<b-col sm="3" v-for="(image, index) in row.item.images" :key="index">
							<span v-show="hasMedia(image)">
								<b-img v-img-orientation-changer :src="image.url" alt="Responsive image" thumbnail
									fluid />
								<a href="#" class="pull-left" @click.prevent="showImage(image.url)">
									Click to view full image
								</a>
							</span>
						</b-col>
					</b-row>

                    <b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Remarks</b>
						</b-col>
						<b-col sm="4">
							<div v-if="row.item.remarks">
								<span class="truncate-text">
									<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
										less="Show Less" type="html" :text="row.item.remarks" />
								</span>
							</div>
						</b-col>
					</b-row>

                </b-card-text>
            </b-tab>
        </b-tabs>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
                    <em class="icon-arrow-up"></em>
                </b-button>
            </b-col>
        </b-row>

        <!-- Modal -->
        <InvalidImageDialog />

    </b-card>
</template>

<script>
// Component
import InvalidImageDialog from '../../transactions/common/ImageViewDialog.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { FileUtil } from '@/utils/fileUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import EventBus from '@/shared/event-bus';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'dispatch-alert-details-view',
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    components: {
        InvalidImageDialog,
        truncate
    },
    computed: {
        sourceCompany() {
            return DispatchUtil.getCompanyLocationDisplay(this.row.item.source);
        },
        destinationCompany() {
            return DispatchUtil.getCompanyLocationDisplay(this.row.item.destination);
        },
    },

    methods: {

        getCoordinates(geoaddress) {
            return LocationUtil.getCoordinates(geoaddress);
        },

        showFormattedDate(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },

        getDisplayValue(value) {
            if (!value || value.length === 0) {
                return '-';
            }
            return value;
        },

        showDispatchLocation(details) {
            const params = {
                source: details.source,
                destination: details.destination,
                courier: {
                    driver: details.driver.name,
                    plateNo: details.transportation.plateNo,
                    geoaddress: details.geoaddress
                },
            };

            EventBus.$emit('onUpdateSelIncidentReportLocationView', params);
            this.$bvModal.show('incident-report-location-view');
        },

        showImage(url) {
            let fileName = FileUtil.getUrlFileName(url);
            EventBus.$emit('onSelectImageView', {
                url: url,
                name: fileName
            });
            this.$bvModal.show('image-view-dialog');
        },

        hasMedia(media) {
            return media && media.url && !_.isEmpty(media.url);
        },
    },
};
</script>