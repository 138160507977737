<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-warning"></i> Incident Report</b-card-title>
			<b-card-sub-title>Summary of all reported incidents from dispatches</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="my-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2" visible>
							<b-card>
								<!-- Date Range -->
								<b-row no-gutters>
									<b-col lg="4" md="5" sm="12" class="mr-4">
										<b-form-group label="Date From">
											<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date From')" class="help-block">
												{{ errors.first('Date From') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="5" sm="12" class="mr-4">
										<b-form-group label="Date To">
											<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date To')" class="help-block">
												{{ errors.first('Date To') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Incident Type">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="incidentTypeOptions"
												:reduce="(incidentType) => incidentType.value"
												v-model="filterBy.incidentType">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a plate number
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Driver">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="driverOptions"
												:reduce="(driver) => driver.value" v-model="filterBy.driver">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a driver
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Dispatch ID"
											description="NOTE: Input the exact Dispatch ID to search">
											<b-form-input id="dispatchId" name="Dispatch ID" type="search"
												class="numFont" v-model="filterBy.dispatchId"
												v-validate="getValidationParam(false, /^DS\d{13}$/)"
												placeholder="DSXXXXXXXXXXXXX" />
											<span v-show="errors.has('Dispatch ID')" class="help-block">
												{{ errors.first('Dispatch ID') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Incident ID"
											description="NOTE: Input the exact Incident ID to search">
											<b-form-input id="incidentId" name="Incident ID" type="text" class="numFont"
												v-model="filterBy.incidentId"
												v-validate="getValidationParam(false, /^IR\d{13}$/)"
												placeholder="IRXXXXXXXXXXXXX" />
											<span v-show="errors.has('Incident ID')" class="help-block">
												{{ errors.first('Incident ID') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Generate
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<div v-if="items.length > 0">
					<b-row>
						<b-col sm="6" md="3" class="mt-4 mb-2">
							<b-dropdown id="incident-select-actions" text=" Select Actions " variant="dark" slot="append">
								<b-dropdown-item>
									<json-excel :data="exportData" :fields="exportFields" type="xls"
										:name="fileName + '.xls'">
										Export Incident Report in Excel
									</json-excel>
								</b-dropdown-item>
								<b-dropdown-item>
									<json-excel :data="exportData" :fields="exportFields" type="csv"
										:name="fileName + '.csv'">
										Export Incident Report to CSV
									</json-excel>
								</b-dropdown-item>
							</b-dropdown>
						</b-col>

						<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
							<b-input-group prepend="Show" append="/ Page">
								<b-form-select :options="pageOptions" v-model="perPage" />
							</b-input-group>
						</b-col>
					</b-row>

					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>

						<template v-slot:cell(dispatchId)="row">
							<span class="numFont">{{ row.item.dispatchId }}</span>
						</template>

						<template v-slot:cell(incidentId)="row">
							<span class="numFont">{{ row.item.incidentId }}</span>
						</template>
						
						<template v-slot:cell(dateCreated)="row">
							{{ row.item.dateCreated ? getDisplayDateTime(row.item.dateCreated) : '-' }}
						</template>

						<template v-slot:cell(actions)="row">
							<span class="text-nowrap">
								<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
									@click.stop="row.toggleDetails" class="mr-1">
									<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
									<i class="fa fa-eye" v-else></i>
								</b-button>
							</span>
						</template>

						<template slot="row-details" slot-scope="row">
							<IncidentReportDetailsView :row="row" />
						</template>
					</b-table>

					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
								v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>

			</div>
		</b-card>

		<!-- Modals here -->
		<IncidentReportLocationView />

	</div>
</template>

<script>
// Components
import IncidentReportDetailsView from '@/views/reports/incidentReport/IncidentReportDetailsView';
import IncidentReportLocationView from '@/views/reports/incidentReport/IncidentReportLocationView';

// Utils
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import reportApi from '@/api/reportApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'incident-report',
	components: {
		IncidentReportDetailsView,
		IncidentReportLocationView,
		JsonExcel,
		Loading
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dispatchId',
					label: 'Dispatch ID',
					sortable: true,
				},
				{
					key: 'incidentId',
					label: 'Incident ID',
					sortable: true,
				},
				{
					key: 'Date Created',
					sortable: true,
				},
				{
					key: 'Reporter',
					sortable: true,
				},
				{
					key: 'Incident Type',
					sortable: true,
				},
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			dateFormatOptions: { ...config.dateFormatOptions },

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				incidentType: config.incidentTypeDefaultValue,
				driver: config.driverDefaultValue,
				dispatchId: '',
				incidentId: '',
			},

			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				incidentType: config.incidentTypeDefaultValue,
				driver: config.driverDefaultValue,
				dispatchId: '',
				incidentId: '',
			},

			prevFilterBy: {},
			params: {},

			allIncidentTypeObj: {},
			allDriversObj: {},
			allIncidentReportsObj: {},

			incidentTypeOptions: [],
			driverOptions: [],

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			isLoading: false,
		};
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		exportData() {
			return this.items;
		},

		exportFields() {
			return {
				'Dispatch ID': 'dispatchId',
				'Incident ID': 'incidentId',
				'Date Created': 'Date Created',
				'Reporter': 'driver.name',
				'Incident Type': 'incidentType.name',
				'Plate No.': 'transportation.plateNo',
				'Latitude': 'geoaddress.latitude',
				'Longitude': 'geoaddress.longitude',
				'Summary': 'summary',
				'Remarks': 'remarks',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Incident-Report-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	created() {
		// Set Default Date Range
		this.defaultFilterBy.dateFrom = this.dateFrom;
		this.defaultFilterBy.dateTo = this.dateTo;
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.allIncidentTypeObj = { ...this.$store.getters.incidentTypes };
				this.allDriversObj = { ...this.$store.getters.driverUsers };

				this.incidentTypeOptions = DropDownItemsUtil.retrieveIncidentTypes(this.allIncidentTypeObj, false);

				if (this.isSuperAdmin) {
					this.driverOptions = DropDownItemsUtil.retrieveActiveDrivers(this.allDriversObj, false);
				} else {
					this.driverOptions = DropDownItemsUtil.retrieveActiveDriversByCompany(this.allDriversObj, this.loggedUserCompany);
				}

				this.resetFilters();
				await this.retrieveData();
				
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}

		}, config.timeout);
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;

				filter.companyId = this.loggedUserCompany.id;
				let { data } = await reportApi.getIncidentReport(
					filter,
					view,
					this.loggedUser.id
				);

				this.allIncidentReportsObj = data.incidentReports;

				if (_.isEmpty(this.allIncidentReportsObj)) {
					this.$toaster.warning('No Results found.');
				} else {
					this.processIncidentReports(this.allIncidentReportsObj);
				}

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		processIncidentReports(incidentReports) {
			this.allIncidentReportsObj = incidentReports;

			let filteredObjs = { ...this.allIncidentReportsObj };
			_.forEach(this.allIncidentReportsObj, (incidentReport, id) => {

				let dispatchId = this.filterBy.dispatchId;
				if (
					dispatchId &&
					dispatchId.length > 0 &&
					dispatchId !== incidentReport.dispatchId
				) {
					delete filteredObjs[id];
				}

				let incidentId = this.filterBy.incidentId;
				if (
					incidentId &&
					incidentId.length > 0 &&
					incidentId !== incidentReport.incidentId
				) {
					delete filteredObjs[id];
				}

				let incidentType = this.filterBy.incidentType;
				if (
					incidentType &&
					incidentType.id &&
					incidentType.id.length > 0 &&
					incidentType.name !== incidentReport.incidentType.name
				) {
					delete filteredObjs[id];
				}

				let driver = this.filterBy.driver;
				if (
					driver &&
					driver.id &&
					driver.id !== incidentReport.driver.userId
				) {
					delete filteredObjs[id];
				}

			});

			this.items = Object.values(filteredObjs);
			this.items.forEach((item) => {
				item['Dispatch ID'] = item.dispatchId;
				item['Incident ID'] = item.incidentId;
				item['Reporter'] = item.driver.name;
				item['Incident Type'] = item.incidentType.name;
				item['Date Created'] = this.getDisplayDateTime(item.dateCreated);
			});
			this.items = _.sortBy(this.items, ['Dispatch ID']);
			this.totalRows = this.items.length;
		},

		// UTILS
		getDisplayDateTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	}
};
</script>