import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getInventorySummaryReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getInventorySummaryReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getAssetsWithTruckersReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getAssetsWithTruckersReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getDispatchAlertsReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getDispatchAlertsReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    getIncidentReport(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getIncidentReport`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },
}